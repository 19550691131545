import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

const NewGamesAnnouncementPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes('pbingo') || url.includes('phoenix') || url.includes('local')) {
      setIsOpen(true);
    }
  }, []);

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const rotateY = ((x - centerX) / centerX) * 10;
    const rotateX = ((centerY - y) / centerY) * 10;
    
    setRotateX(rotateX);
    setRotateY(rotateY);
  };

  const handleMouseLeave = () => {
    setRotateX(0);
    setRotateY(0);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-40 flex justify-center items-center z-50 perspective-1000">
      <Confetti width={window.innerWidth} height={window.innerHeight} />
      <div
        className="relative bg-gradient-to-br from-purple-600 to-indigo-800 p-1 rounded-xl max-w-xl w-full mx-4 text-center shadow-2xl transform-gpu transition-transform duration-200 ease-out"
        style={{
          transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
          boxShadow: '0 15px 30px -8px rgba(0, 0, 0, 0.5), 0 0 20px 5px rgba(139, 92, 246, 0.3)',
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div className="absolute inset-0 bg-white bg-opacity-10 rounded-xl backdrop-blur-sm -z-10"></div>
        
        <h1 className="text-pink-200 text-2xl font-bold mb-2 transform hover:scale-105 transition-transform duration-300 ">
          የፊንክስ ጌምስ 4ኛ አመት በአል!
        </h1>
        <h2 className="text-yellow-300 text-3xl font-bold mb-2 transform hover:scale-105 transition-transform duration-300">
          ታላቅ ሽልማት!
        </h2>
        
        <div className="space-y-4 mb-1">
          <p className="text-white text-lg">
            ለሁሉም ኤጀንቶች በ4ኛ አመት በአላችን ምክንያት:
          </p>
          
          <ul className="text-white text-lg list-none space-y-2 max-w-md mx-auto">
            <li className="bg-gradient-to-r from-green-500 to-teal-500 rounded-lg py-2 px-4 transform hover:scale-102 transition-all duration-300">
              <span className="font-bold">5 ቀን ነጻ የቢንጎ ጨዋታ!</span>
            </li>
            <li className="bg-gradient-to-r from-blue-500 to-purple-500 rounded-lg py-2 px-4 transform hover:scale-102 transition-all duration-300">
              <span className="font-bold">በየቀኑ 5000 ብር ሽልማት ለተጫዋቾች</span>
              <span className="block text-sm">(ከታህሳስ 5 - ጥር 5)</span>
            </li>
            <li className="bg-gradient-to-r from-red-500 to-pink-500 rounded-lg py-2 px-4 transform hover:scale-102 transition-all duration-300">
              <span className="font-bold">አንድ ወር ነጻ የቢንጎ ጨዋታ የማሸነፊያ ዕድል ለኤጀንቶች</span>
              <span className="block text-sm">(ቴሌግራም ቻናል ሲቀላቀሉ)</span>
            </li>
          </ul>
        </div>

        <p className="text-red-200 text-xl font-semibold mb-4">
          አዲስ! ከቤትዎ ሆነው ከኤጀንትዎ ጋር Bingo መጫወት ይችላሉ!
          <span className="block text-sm mt-2">
            ከታህሳስ 5 ጀምሮ ለሻሸመኔ እና ሀዋሳ ኤጀንቶች።
          </span>
        </p>

        <p className="text-yellow-200 text-lg font-semibold">
          ለመቀላቀል እና ሽልማቶችን ለማግኘት በቴሌግራም ቻናላችን ይቀላቀሉን{' '}
          <a 
            href="https://t.me/phoenixbingo"
            className="text-green-400 underline hover:text-yellow-300 transition-colors duration-300"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://t.me/phoenixbingo
          </a>
          <span className="text-green-400 block text-sm mt-2">
            ወይም በቴሌግራም ላይ Phoenix Bingo ብለው ይፈልጉን
          </span>
        </p>

        <button 
          onClick={() => setIsOpen(false)}
          className="relative bg-gradient-to-r from-red-600 to-red-700 text-white text-lg font-bold py-2 px-6 rounded-full transform hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-xl mt-4"
        >
          <span className="relative z-10">አሁን ይቀላቀሉን! Phoenix Bingo ብለው በቴሌግራም ይፈልጉን </span>
          <div className="absolute inset-0 rounded-full bg-white opacity-0 hover:opacity-20 transition-opacity duration-300"></div>
        </button>
      </div>
      <div className="fixed bottom-4 right-4 bg-gradient-to-r from-green-600 to-green-700 text-white px-4 py-2 rounded-lg shadow-lg animate-fade-in-up">
        <div className="font-bold">ለኤጀንቶች!</div>
        <div className="text-sm">የዲፖዚት ቦነስ - ከታህሳስ 5 ጀምሮ</div>
      </div>
      
      <style jsx>{`
        .perspective-1000 {
          perspective: 1000px;
        }
        .glow-yellow {
          box-shadow: 0 0 20px rgba(251, 191, 36, 0.5);
        }
        .text-shadow-glow {
          text-shadow: 0 0 20px rgba(251, 191, 36, 0.5);
        }
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default NewGamesAnnouncementPopup;
